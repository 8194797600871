import React from "react";
import { PageLayout } from "@components";
import Margins from "@components/Margins/Margins";
import { JoinInstitutionsV2 } from "pages/landingpagev2/_components/_JoinInstitutionsV2/_JoinInstitutionsV2";
import { ReportsHero } from "./_components/_ReportsHero/_ReportsHero";
import FeatureCard, { FeatureCardLayout, FeatureCardProps } from "@components/FeatureCard/FeatureCard";
import { StaticImage } from "gatsby-plugin-image";
import * as styles from './reports.module.scss';

const cards: FeatureCardProps[] = [
	{
		title: "Comprehensive reporting made easy",
		image: (
			<StaticImage
				src={"./_assets/live-editing-file.png"}
				alt="Comprehensive reporting made easy"
			/>
		),
		list: [
			"Export experiments straight from the main page, keeping your data safe",
			"Customize your report by selecting the projects that matter to you",
			"Save time and energy by combining project selection and data export in a single platform",
		],
		orientation: "right",
	},
	{
		title: "Pick and preview your way",
		image: (
			<StaticImage
				src={"./_assets/add-files-to-report.png"}
				alt="Pick and preview your way"
			/>
		),
		list: [
			"Our advanced features let you handpick individual experiments for your report",
			"Simply click on the experiment’s name to get a detailed preview before finalizing your selections",
			"Adjust every detail as needed, giving you control and transparency",
		],
	},
	{
		title: "PDFs made simple",
		image: (
			<StaticImage
				src={"./_assets/protocol-preview.png"}
				alt="PDFs made simple"
			/>
		),
		list: [
			"Convert your meticulous reports into shareable PDF formats, perfect for offline access",
			"Keep all your reports safe and easy to retrieve in a dedicated repository",
			"Make the most of this organized record-keeping feature by simplifying audits and historical data checks",
		],
		orientation: "right",
	},
];

export default function LiveEditing() {
	return (
		<PageLayout
			seoOptions={{ type: "PREDEFINED", pageName: "reports" }}
		>
			<Margins>
				<ReportsHero />
				<FeatureCardLayout className={styles.layout}>
					{cards.map((card, index) => {
						return <FeatureCard {...card} key={index} />;
					})}
				</FeatureCardLayout>
				<JoinInstitutionsV2
					outerContainerClassName={styles.joinInstitutions}
				/>
			</Margins>
		</PageLayout>
	);
}
